import React from 'react';
import Layout from '../../components/Layout';
import { withIntl } from '../../i18n';
import FormPage from '../../templates/FormPage';
const host = process.env.GATSBY_HOST;

const sales = {
  title: 'vacancyTitle',
  subTitle: 'vacancyText',
  form: {
    title: '',
    type: 'addVacancy',
    fields: [
      {
        type: 'company',
        label: 'formCompany',
        value: ''
      },
      {
        type: 'jobTitle',
        label: 'formJobTitle',
        value: ''
      },
      {
        type: 'projectDescription',
        label: 'formProjectDescription',
        value: ''
      },
      {
        type: 'requirements',
        label: 'formRequirements',
        value: ''
      },
      {
        type: 'responsibilities',
        label: 'formLastResponsibilities',
        value: ''
      },
      {
        type: 'benefits',
        label: 'formBenefits',
        value: ''
      },
      {
        type: 'additionalInformation',
        label: 'formAdditionalInformation',
        value: ''
      },
      {
        type: 'responsiblePerson',
        label: 'formCOntactPerson',
        value: ''
      },
      {
        type: 'email',
        label: 'formEmail',
        value: ''
      }
    ],
    button: {
      text: 'formSendSubmitVacancy',
      link: `${host}/api/1/json/public/513644/6d85995d468d2cd7d26930393473dff049842412`,
    }
  }
};

class Vacancy extends React.PureComponent {
  render() {
    return (
      <Layout>
        <FormPage data={sales} />
      </Layout>
    );
  }
}

export default withIntl(Vacancy);

